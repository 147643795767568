<div class="container-fluid">
  @if (loading) {
    <div class="row vh-100 align-content-center">
      <div class="col text-center">
        <i class="fa-light fa-spinner spinning ms-2 my-fs-5"></i>
      </div>
    </div>
  }

  @if (!loading) {
    @if (authService.userBundle === null) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">{{ data.formationName | ms }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">{{ data.formationSessionName }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">
            {{
              'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                | translate
                  : {
                      startDate: data.minDate | localizedDate,
                      endDate: data.maxDate | localizedDate,
                    }
            }}
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col"><hr /></div>
      </div>
    }
    @if (participantAttendance && participantAttendance.attendanceEnabled) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <div class="container-fluid bg-white rounded-3 border p-3">
            <div class="row align-items-center">
              <div class="col-2 text-center"><i class="fa-light fa-exclamation-triangle fa-3x text-danger"></i></div>
              <div class="col">
                <div>
                  {{
                    "mif.satisfaction.answer.Attention l'émargement est activé sur cette session et il semblerait que vous n'ayez pas émargé à toutes les sessions"
                      | translate
                  }}
                </div>
                <div class="mt-2">
                  {{ "mif.satisfaction.answer.Voici les dates auxquelles vous n'avez pas émargé :" | translate }}
                </div>
                @if (missingAttendance.length > 0) {
                  <ul class="mt-1">
                    @for (item of missingAttendance; track $index) {
                      <li>{{ item.date | localizedDate }} {{ 'common.enum.FormationSessionDatePeriod.' + item.period.lib | translate }}</li>
                    }
                  </ul>
                }
                <div class="mt-2">
                  {{ 'mif.satisfaction.answer.Votre réponse sera enregistrée mais non comptabilisée' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <div class="row mt-3">
      <div class="col">
        <h4 class="text-info text-center">{{ 'mif.satisfaction.answer.Ma satisfaction' | translate }}</h4>
      </div>
    </div>
    @if (view()) {
      <div class="row align-center mt-3">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <div class="container-fluid bg-white rounded-3 border pb-3">
            <div class="row justify-content-end">
              <div class="col">
                <hiji-tag-radar-chart [data]="radarData"></hiji-tag-radar-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    @if (data.oogs.length === 0) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
          <ul class="errors">
            <li>
              {{'global.Erreur de configuration, veuillez contacter votre administrateur en précisant le code suivant : {{code}}' | translate:{ code:
              'no_repository_for_session_'+idFormationSession} }}
            </li>
          </ul>
        </div>
      </div>
    }
    <div class="row">
      @for (og of data.oogs | orderBy: 'order'; track og; let first = $first) {
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
          <hiji-observable-gesture-evaluation
            [criteria]="getCriteria(og.idCriteria)"
            [observableGesture]="og"
            [observed]="og.observed"
            [text]="og.text"
            [value]="og.value"
            (observe)="observe($event, og)"
            (observeText)="observeText($event, og)"
            [first]="first"
            color="--hiji-mif-satisfaction"
            [disabled]="(dataParticipant !== null && dataParticipant.satisfactionDone) || view()"
          ></hiji-observable-gesture-evaluation>
        </div>
      }
    </div>
    @if (expectations !== null && expectations.expectationsParticipantEnabled) {
      <div class="row mt-5">
        <div class="col">
          <h4 class="text-info text-center">{{ 'mif.satisfaction.answer.Ma satisfaction par rapport à mes attentes' | translate }}</h4>
        </div>
      </div>
      <div class="row">
        @for (expectation of getMyExpectations(); track $index) {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
            <div class="bg-white rounded-3 border p-2">
              <hiji-observable-gesture-result
                [criteria]="expectations.criteriaExpectations"
                [answers]="computeAnswers(expectation)"
                [calculateDonutByNumberOfAnswerTypes]="true"
                [showAnswerDates]="true"
                [showSkill]="false"
                [showMickeyEars]="false"
                [observableGesture]="getExpectationAsOG(expectation)"
                (observe)="observeCriteria($event, expectation)"
                [evaluationMode]="!view()"
              ></hiji-observable-gesture-result>
            </div>
          </div>
        } @empty {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
            <div class="bg-white rounded-3 border p-3">
              {{ 'mif.expectation.results.Aucune attente définie par le participant' | translate }}
            </div>
          </div>
        }
      </div>
    }
    @if (!alreadyAnswer && !view()) {
      <div class="row mt-3">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 pointer mt-3">
          <button class="btn btn-secondary btn-lg border btn-block w-100 text-white" (click)="save()" [disabled]="loadingSave">
            <span>{{ 'global.Valider' | translate }}</span>
            @if (!loadingSave) {
              <i class="fa-light fa-check m-2"></i>
            }
            @if (loadingSave) {
              <i class="fa-light fa-spinner spinning ms-2"></i>
            }
          </button>
        </div>
      </div>
    }
  }
</div>
