import { Injectable, inject } from '@angular/core';
import { L } from '@ic2/ic2-lib';
import { AuthService, FormationSessionParticipantDTO, FormationSessionParticipantService } from 'common';

@Injectable({
  providedIn: 'root',
})
export class MifParticipantDataService {
  private formationSessionParticipantService = inject(FormationSessionParticipantService);
  private authService = inject(AuthService);

  public dataParticipant: FormationSessionParticipantDTO;
  loading: boolean = false;
  dataParticipantLoaded: boolean = false;

  load(idFormationSession: number, idParticipant: number): Promise<void> {
    this.loading = true;
    if (this.dataParticipantLoaded) {
      this.loading = false;
      return Promise.resolve();
    } else {
      return new Promise<void>((resolve, reject) => {
        this.formationSessionParticipantService.getDataSession(idParticipant, idFormationSession).subscribe(
          (data) => {
            this.loading = false;
            this.dataParticipant = data;
            this.dataParticipantLoaded = true;
            resolve();
          },
          (err) => {
            L.e(err);
            reject(err);
          }
        );
      });
    }
  }

  reload(idFormationSession: number, idParticipant: number): Promise<void> {
    this.dataParticipantLoaded = false;
    return this.load(idFormationSession, idParticipant);
  }
}
